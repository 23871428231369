import styled from "@emotion/styled";
import type { FunctionComponent } from "react";
import { useEffect } from "react";
import { graphql, useQueryLoader } from "react-relay";

import { ArticleSpeechSkipContainer } from "scmp-app/components/article/article-speech";
import { ClientSideSuspense } from "scmp-app/components/common/client-side-suspense";
import { PhotoGalleryWidget } from "scmp-app/components/schema-render/common/div/photo-gallery/photo-gallery-widget";
import {
  BottomCell,
  ImageGrid,
  MainCell,
} from "scmp-app/components/schema-render/common/div/photo-gallery/photo-gallery-widget/styles";
import type {
  ContentSchemaRenderProps,
  RenderComponent,
} from "scmp-app/components/schema-render/content";
import type { contentSchemaRenderContent$data } from "scmp-app/queries/__generated__/contentSchemaRenderContent.graphql";
import type { photoGalleryQuery } from "scmp-app/queries/__generated__/photoGalleryQuery.graphql";

import { Container, Header, HeaderDivider, Label, StyledLoading } from "./styles";

export const query = graphql`
  query photoGalleryQuery($entityId: String!) {
    content(contentType: GALLERY, filter: { entityId: $entityId }) {
      ...photoGalleryWidgetGallery
    }
  }
`;

type Props = {
  className?: string;
  reference?: contentSchemaRenderContent$data;
} & ContentSchemaRenderProps;

const Component: FunctionComponent<Props> = ({ className, reference, schemaNode }) => {
  const [queryReference, loadQuery, disposeQuery] = useQueryLoader<photoGalleryQuery>(query);
  const galleryEntityId = schemaNode.attribs?.["data-gallery-nid"];

  useEffect(() => {
    if (!galleryEntityId) return;
    loadQuery({ entityId: galleryEntityId });

    return disposeQuery;
  }, [disposeQuery, galleryEntityId, loadQuery]);

  if (!galleryEntityId || !reference) return null;

  const label = schemaNode.attribs?.["data-gallery-label"];
  const renderFallback = () => (
    <>
      {label && <Header />}
      <ImageGrid>
        <MainCell />
        <BottomCell />
        <StyledLoading />
      </ImageGrid>
    </>
  );

  return (
    <ArticleSpeechSkipContainer>
      <Container>
        <ClientSideSuspense fallback={renderFallback()}>
          {queryReference ? (
            <>
              {label && (
                <Header>
                  <Label>{label}</Label>
                  <HeaderDivider />
                </Header>
              )}

              <PhotoGalleryWidget
                className={className}
                photoGalleryQueryReference={queryReference}
                reference={reference}
              />
            </>
          ) : (
            renderFallback()
          )}
        </ClientSideSuspense>
      </Container>
    </ArticleSpeechSkipContainer>
  );
};

export const PhotoGallery: RenderComponent<Props> = styled(Component)``;
PhotoGallery.isHandlingOwnChildren = true;
