import styled from "@emotion/styled";
import { fontRoboto, fontRobotoCondensed } from "@product/scmp-sdk";

import { Loading } from "scmp-app/components/common/loading";

import PhotoGalleryIcon from "./icon-photo-gallery.svg";

export const Container = styled.div`
  margin-block: 12px 28px;
`;

export const Header = styled.div`
  grid-area: header;

  display: grid;
  grid:
    "content" min-content
    / 100%;
  align-items: center;

  block-size: 20px;
  margin-block-end: 8px;
`;

export const Label = styled.div`
  grid-area: content;

  justify-self: start;

  padding: 4px;

  font-size: 14px;
  font-family: ${fontRobotoCondensed};
  line-height: 16px;

  border: 1px solid #d8d8d8;

  background: #ffffff;
`;

export const HeaderDivider = styled.div`
  grid-area: content;

  order: -1;

  inline-size: 100%;
  block-size: 1px;

  background: #d8d8d8;
`;

export const StyledLoading = styled(Loading)`
  position: absolute;
  inset-block-start: 50%;
  inset-inline-start: 50%;

  transform: translate(-50%, -50%);
`;

export const Indicator = styled.div`
  grid-area: main;

  display: flex;
  flex-flow: column nowrap;
  place-self: start end;
  justify-content: center;
  align-items: center;

  inline-size: 60px;
  block-size: 60px;
  margin: 16px;

  border-radius: 50%;

  background: #141518;

  cursor: pointer;

  opacity: 0.9;

  ${props => props.theme.breakpoints.up("tablet")} {
    inline-size: 80px;
    block-size: 80px;
    margin: 24px;
  }
`;

export const IndicatorIcon = styled(PhotoGalleryIcon)`
  inline-size: 16px;
  block-size: 16px;
  ${props => props.theme.breakpoints.up("tablet")} {
    inline-size: 20px;
    block-size: 20px;
  }
`;

export const IndicatorValue = styled.div`
  margin-block-start: 6px;

  color: #ffffff;
  font-size: 14px;
  font-family: ${fontRoboto};
  line-height: 14px;
  ${props => props.theme.breakpoints.up("tablet")} {
    font-size: 18px;
    line-height: 18px;
  }
`;
