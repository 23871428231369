import type { ShareButtonProps, SharePositionType, SocialShareType } from "@product/scmp-sdk";
import { findFirstNonEmptyString, notEmpty } from "@product/scmp-sdk";
import uniq from "lodash/uniq";
import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import type { contentShareWidgetContent$key } from "scmp-app/queries/__generated__/contentShareWidgetContent.graphql";
import type { contentShareWidgetLiveContent$key } from "scmp-app/queries/__generated__/contentShareWidgetLiveContent.graphql";

import { Container, StyledShareButton } from "./styles";

export type Props = {
  liveContentReference?: contentShareWidgetLiveContent$key;
  onClick?: (type: SocialShareType) => void;
  position?: SharePositionType;
  reference: contentShareWidgetContent$key;
  types: SocialShareType[];
} & Omit<ShareButtonProps, "relativeUrl" | "shortUrl" | "title" | "utm" | "type">;

export const ContentShareWidget: FunctionComponent<Props> = ({
  className,
  liveContentReference: liveContentReference_,
  onClick,
  position,
  reference: reference_,
  types,
  ...props
}) => {
  const content = useFragment(
    graphql`
      fragment contentShareWidgetContent on Content {
        entityId
        urlAlias
        shortUrl
        socialHeadline
        headline
      }
    `,
    reference_,
  );

  const liveContent = useFragment(
    graphql`
      fragment contentShareWidgetLiveContent on LiveContent {
        title
        entityId
        body {
          text
        }
      }
    `,
    liveContentReference_ ?? null,
  );

  const appendLiveContentHashToUrl = (url: string) => {
    if (url && liveContent?.entityId) {
      return `${url}#live-${liveContent.entityId}`;
    }
    return url;
  };

  const getTitle = (type: SocialShareType) => {
    const separator = "\r\n";
    const title = findFirstNonEmptyString(
      liveContent?.title,
      content.socialHeadline,
      content.headline,
    );
    if (liveContent?.title && type === "email") {
      return [liveContent?.title, liveContent?.body?.text?.split("\n").join(separator)]
        .filter(notEmpty)
        .join(`${separator}${separator}`);
    }
    return title;
  };

  return (
    <Container className={className}>
      {uniq(types).map(type => (
        <StyledShareButton
          className={type}
          key={type}
          position={position}
          relativeUrl={appendLiveContentHashToUrl(content.urlAlias)}
          shortUrl={appendLiveContentHashToUrl(content.shortUrl ?? "")}
          title={getTitle(type)}
          type={type}
          utm={{
            campaign: content.entityId,
            medium: "share_widget",
          }}
          onClick={() => onClick?.(type)}
          {...props}
        />
      ))}
    </Container>
  );
};

ContentShareWidget.displayName = "ContentShareWidget";
