import type { Theme } from "@emotion/react";
import type { StyledComponent } from "@emotion/styled";
import type { FunctionComponent, PartialPropsOf, ReactNode } from "react";
import { createContext, useContext, useMemo } from "react";
import { graphql, useFragment } from "react-relay";

import type { Props as AdSlotProps } from "scmp-app/components/advertisement/ad-slots/ad-slot";
import { Blockquote } from "scmp-app/components/schema-render/common/blockquote";
import { BlockquoteQuote } from "scmp-app/components/schema-render/common/blockquote-quote";
import { Br } from "scmp-app/components/schema-render/common/br";
import { Div } from "scmp-app/components/schema-render/common/div";
import { Heading } from "scmp-app/components/schema-render/common/heading";
import { Iframe } from "scmp-app/components/schema-render/common/iframe";
import { Image } from "scmp-app/components/schema-render/common/image";
import { InlineWidget } from "scmp-app/components/schema-render/common/inline-widget";
import { Link } from "scmp-app/components/schema-render/common/link";
import { Paragraph } from "scmp-app/components/schema-render/common/paragraph";
import { Table } from "scmp-app/components/schema-render/common/table";
import { Text } from "scmp-app/components/schema-render/common/text";
import { normalizeJsxAttribute } from "scmp-app/lib/utils";
import type {
  contentSchemaRenderContent$data,
  contentSchemaRenderContent$key,
} from "scmp-app/queries/__generated__/contentSchemaRenderContent.graphql";

import {
  StyledCaption,
  StyledEm,
  StyledLi,
  StyledOl,
  StyledPre,
  StyledS,
  StyledSpan,
  StyledStrong,
  StyledSub,
  StyledSup,
  StyledTbody,
  StyledTd,
  StyledTh,
  StyledThead,
  StyledTr,
  StyledUl,
  StyledUnderline,
} from "./styles";
import type { SupportedContentNode } from "./types";

export type ContentSchemaRenderProps = {
  children?: ReactNode;
  index: number;
  parentSchemaNode?: SchemaNode;
  schemaNode: SchemaNode;
  theme?: Theme;
};

export type RenderComponent<
  Props extends ContentSchemaRenderProps = ContentSchemaRenderProps,
  Component = StyledComponent<Props>,
> = Component & {
  // IsHandlingOwnChildren means the component will render it's own children
  isHandlingOwnChildren?: boolean;
};

export type ComponentMap<Component = RenderComponent> = Partial<
  Record<SupportedContentNode, Component>
>;

export type RenderFunction<Props> = (
  props: Props,
  itemIndex: number,
  index: number,
  children: ReactNode[] | undefined,
  schemaNode: SchemaNode,
  parentSchemaNode?: SchemaNode,
) => ReactNode;

export type DefaultRenderMapFunction = {
  a: RenderFunction<PartialPropsOf<typeof Link>>;
  blockquote: RenderFunction<PartialPropsOf<typeof Blockquote>>;
  "blockquote-quote": RenderFunction<PartialPropsOf<typeof BlockquoteQuote>>;
  br: RenderFunction<PartialPropsOf<typeof Br>>;
  caption: RenderFunction<PartialPropsOf<typeof StyledCaption>>;
  div: RenderFunction<PartialPropsOf<typeof Div>>;
  em: RenderFunction<PartialPropsOf<typeof StyledEm>>;
  h1: RenderFunction<PartialPropsOf<typeof Heading>>;
  h2: RenderFunction<PartialPropsOf<typeof Heading>>;
  h3: RenderFunction<PartialPropsOf<typeof Heading>>;
  h4: RenderFunction<PartialPropsOf<typeof Heading>>;
  h5: RenderFunction<PartialPropsOf<typeof Heading>>;
  h6: RenderFunction<PartialPropsOf<typeof Heading>>;
  iframe: RenderFunction<PartialPropsOf<typeof Iframe>>;
  img: RenderFunction<PartialPropsOf<typeof Image>>;
  "inline-widget": RenderFunction<PartialPropsOf<typeof InlineWidget>>;
  li: RenderFunction<PartialPropsOf<typeof StyledLi>>;
  ol: RenderFunction<PartialPropsOf<typeof StyledOl>>;
  p: RenderFunction<PartialPropsOf<typeof Paragraph>>;
  pre: RenderFunction<PartialPropsOf<typeof StyledPre>>;
  s: RenderFunction<PartialPropsOf<typeof StyledS>>;
  span: RenderFunction<PartialPropsOf<typeof StyledSpan>>;
  strong: RenderFunction<PartialPropsOf<typeof StyledStrong>>;
  sub: RenderFunction<PartialPropsOf<typeof StyledSub>>;
  sup: RenderFunction<PartialPropsOf<typeof StyledSup>>;
  table: RenderFunction<PartialPropsOf<typeof Table>>;
  tbody: RenderFunction<PartialPropsOf<typeof StyledTbody>>;
  td: RenderFunction<PartialPropsOf<typeof StyledTd>>;
  text: RenderFunction<PartialPropsOf<typeof Text>>;
  th: RenderFunction<PartialPropsOf<typeof StyledTh>>;
  thead: RenderFunction<PartialPropsOf<typeof StyledThead>>;
  tr: RenderFunction<PartialPropsOf<typeof StyledTr>>;
  u: RenderFunction<PartialPropsOf<typeof StyledUnderline>>;
  ul: RenderFunction<PartialPropsOf<typeof StyledUl>>;
};

export type Props<
  ExtraRenderMapFunction = Partial<
    Record<SupportedContentNode, RenderFunction<Record<string, unknown>>>
  >,
> = {
  advertising?: Advertising;
  /**
   * This map will merge with the default component map
   */
  extraComponentMap?: ComponentMap<RenderComponent | null>;
  /**
   * This function map will merge with the default render function map
   * It can be useful when we need the relay data which is not inside the ContentSchemaRender
   */
  extraRenderFunctionMap?: ExtraRenderMapFunction;
  reference: contentSchemaRenderContent$key;
  schema: Nullish<Schema>;
};

type Advertising = {
  instream?: "instream1" | "instream3";
  targeting?: AdSlotProps["targeting"];
  zone?: AdSlotProps["zone"];
};

export const RenderContext = createContext<{ advertising?: Advertising; schema: Schema }>({
  schema: [],
});
export const useRenderContext = () => useContext(RenderContext);

export const ContentSchemaRender: FunctionComponent<Props> = ({
  advertising,
  extraComponentMap = {},
  extraRenderFunctionMap = {},
  reference: reference_,
  schema,
  ...attributes
}) => {
  const content = useFragment(
    graphql`
      fragment contentSchemaRenderContent on Content {
        ...blockquoteQuoteContent
        ...imageContent
        ...inlineWidgetContent
        ...photoGalleryWidgetContent
        ...scmpYoutubeContent
      }
    `,
    reference_,
  );

  const defaultComponentMap: ComponentMap = {
    a: Link,
    blockquote: Blockquote,
    "blockquote-quote": BlockquoteQuote,
    br: Br,
    caption: StyledCaption,
    div: Div,
    em: StyledEm,
    h1: Heading,
    h2: Heading,
    h3: Heading,
    h4: Heading,
    h5: Heading,
    h6: Heading,
    iframe: Iframe,
    img: Image,
    "inline-widget": InlineWidget,
    li: StyledLi,
    ol: StyledOl,
    p: Paragraph,
    pre: StyledPre,
    s: StyledS,
    span: StyledSpan,
    strong: StyledStrong,
    sub: StyledSub,
    sup: StyledSup,
    table: Table,
    tbody: StyledTbody,
    td: StyledTd,
    text: Text,
    th: StyledTh,
    thead: StyledThead,
    tr: StyledTr,
    u: StyledUnderline,
    ul: StyledUl,
  };

  const componentMap = {
    ...defaultComponentMap,
    ...extraComponentMap,
  } as ComponentMap;
  const defaultRenderMapFunctions = Object.entries(componentMap).reduce(
    (accumulator, [type, Component_]) => {
      if (!Component_) {
        return accumulator;
      }
      type ContentSchemaPropsWithReference = {
        reference: contentSchemaRenderContent$data;
      } & ContentSchemaRenderProps;
      type RenderComponent<
        Props extends ContentSchemaPropsWithReference = ContentSchemaPropsWithReference,
        Component = StyledComponent<Props>,
      > = Component & {
        isHandlingOwnChildren?: boolean;
      };
      const Component = Component_ as unknown as RenderComponent;

      accumulator[type as keyof DefaultRenderMapFunction] = (
        props,
        keyIndex,
        index,
        children,
        schemaNode,
        parentSchemaNode,
      ) => (
        <Component
          {...props}
          index={index}
          key={keyIndex}
          parentSchemaNode={parentSchemaNode}
          reference={content}
          schemaNode={schemaNode}
        >
          {children}
        </Component>
      );
      return accumulator;
    },
    {} as DefaultRenderMapFunction,
  );

  const renderFunctions = { ...defaultRenderMapFunctions, ...extraRenderFunctionMap };

  const deepRender = (
    index: number,
    itemIndex: number,
    schemaNode: SchemaNode,
    parentSchemaNode?: SchemaNode,
  ): ReactNode | null => {
    const type = schemaNode.type as keyof DefaultRenderMapFunction;
    const Component = componentMap[type];
    const renderFunction = renderFunctions[type];

    if (!Component || !renderFunction) {
      return null;
    }
    const renderChildren = () =>
      schemaNode.children?.reduce((childrenComponent, child, index_) => {
        const childComp = deepRender(index, index_, child, schemaNode);
        childComp && childrenComponent.push(childComp);
        return childrenComponent;
      }, [] as ReactNode[]);

    return renderFunction(
      {
        ...normalizeJsxAttribute({ ...schemaNode.attribs, ...attributes }),
      },
      itemIndex,
      index,
      Component.isHandlingOwnChildren ? [] : renderChildren(),
      schemaNode,
      parentSchemaNode,
    );
  };

  const contextValue = useMemo(
    () => ({
      advertising,
      schema: schema ?? [],
    }),
    [advertising, schema],
  );

  if (!schema) return null;
  return (
    <RenderContext.Provider value={contextValue}>
      {schema.map((s, index) => deepRender(index, index, s, undefined))}
    </RenderContext.Provider>
  );
};

ContentSchemaRender.displayName = "ContentSchemaRender";
