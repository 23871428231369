import { css } from "@emotion/react";
import styled from "@emotion/styled";

type Props = {
  $hasMedia: boolean;
};

export const TweetContainer = styled.div<Props>`
  inline-size: 100%;

  ${props => css`
    min-block-size: ${props.$hasMedia ? "515px" : "225px"};
  `}

  > div > div {
    display: flex;
    justify-content: center;
  }
`;
