import { notEmpty } from "@product/scmp-sdk";

import { StaticSourceUrl, VideoSource } from "./enums";

export const isScmpYoutubeVideo = (
  attributes:
    | {
        "data-video-source"?: string;
        "data-youtube-id"?: string;
      }
    | undefined,
) =>
  attributes?.["data-video-source"] === VideoSource.SCMP &&
  notEmpty(attributes?.["data-youtube-id"]);

export const isDataWrapper = (source: string) => source.includes(StaticSourceUrl.DataWrapper);

export const checkSchemaNodeHasScmpYoutubeVideo = (node: SchemaNode) => {
  if (!node) return false;
  const checkIsScmpYoutubeVideo = (node: SchemaNode): boolean => {
    if (node?.children && node?.children.length > 0)
      return node.children?.some(n => checkIsScmpYoutubeVideo(n));
    return isScmpYoutubeVideo(node.attribs);
  };
  return checkIsScmpYoutubeVideo(node);
};

export const getDimensionWithUnit = (
  dimension?: string | number,
  defaultValue: string = "auto",
) => {
  if (!notEmpty(dimension)) return defaultValue;
  if (typeof dimension === "string") {
    if (dimension.endsWith("%")) return dimension;
    if (dimension.endsWith("px")) return dimension;
    dimension = Number.parseFloat(dimension);
  }
  return `${dimension}px`;
};
