import { NoSsr } from "@mui/base";
import type { NewsletterWidgetProps } from "@product/scmp-sdk";
import { NewsletterWidget, notEmpty, useNewsletterApi } from "@product/scmp-sdk";
import type { NewsletterItem } from "@product/scmp-sdk/dist/types/components/newsletter/types";
import { useAsync } from "@react-hookz/web";
import last from "lodash/last";
import { useRouter } from "next/router";
import type { FunctionComponent } from "react";
import { useEffect, useMemo } from "react";

export type Props = {
  className?: string;
  newsletterId?: string;
} & NewsletterWidgetProps;

export const NewsletterWidgetContainer: FunctionComponent<Props> = ({
  className,
  newsletterId,
  newsletterItems,
  ...newsletterProps
}) => {
  const { fetchNewsletterByIds } = useNewsletterApi();
  const { query } = useRouter();

  const utmNewsletterId = useMemo(() => {
    if (!query.utm_content) return;
    const utmContents = Array.isArray(query.utm_content)
      ? query.utm_content
      : query.utm_content.split("_");
    if (utmContents.length <= 1) return;
    return last(utmContents);
  }, [query.utm_content]);

  const needToFetchNewsletterIds = useMemo(
    () => [utmNewsletterId, newsletterId].filter(notEmpty),
    [newsletterId, utmNewsletterId],
  );

  const [{ result: fetchedNewsletterItems = [] }, { execute }] = useAsync(async () => {
    if (needToFetchNewsletterIds.length === 0) return [] as NewsletterItem[];
    return fetchNewsletterByIds(needToFetchNewsletterIds, needToFetchNewsletterIds.length);
  });
  useEffect(() => void execute(), [execute, needToFetchNewsletterIds]);

  // MergedNewsletter =  utm newsletter (if exist) + Newsletters in props + newsletter id from props
  const mergedNewsletters = useMemo(() => {
    const utmNewsletter = utmNewsletterId
      ? fetchedNewsletterItems.find(newsletter => newsletter.entityId === utmNewsletterId)
      : undefined;
    const newsletterFromProps = newsletterId
      ? fetchedNewsletterItems.find(newsletter => newsletter.entityId === newsletterId)
      : undefined;
    const mergedNewsletters = [...newsletterItems, newsletterFromProps].filter(notEmpty);
    return utmNewsletter ? [utmNewsletter, ...mergedNewsletters] : mergedNewsletters;
  }, [fetchedNewsletterItems, newsletterId, newsletterItems, utmNewsletterId]);

  return (
    <NoSsr>
      <NewsletterWidget
        {...newsletterProps}
        className={className}
        newsletterItems={mergedNewsletters}
      />
    </NoSsr>
  );
};

NewsletterWidgetContainer.displayName = "NewsletterWidgetContainer";
