import styled from "@emotion/styled";
import { ShareButton } from "@product/scmp-sdk";

export const Container = styled.div``;

export const StyledShareButton = styled(ShareButton)`
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;

  inline-size: min-content;
  block-size: min-content;
  padding: 0;

  border: none;

  background: transparent;

  cursor: pointer;
`;
